import React from 'react'
import { Link } from 'gatsby'

import styles from './product-update-table.module.scss'






class ProductUpdateTable extends React.Component {
  render() {

       const updateTableProps = this.props.productUpdateTable;
       const filterSlug = this.props.currentSlug;
       var title = ''
       var table = ''
       var logo = ''

       var displayProp = ''
       if(this.props.display) {
         displayProp = this.props.display
       }

       if (this.props.currentSlug && this.props.currentSlug != updateTableProps.tableProduct.slug) {
          return null;
       }


    if(updateTableProps.title){ 
      title = updateTableProps.title;
    }

    if(updateTableProps.titleImage) {
        logo = <img className={"img-responsive"} width={updateTableProps.titleImage ? updateTableProps.titleImage.file.details.image.width : null} height={updateTableProps.titleImage ? updateTableProps.titleImage.file.details.image.height : null} src={updateTableProps.titleImage ? updateTableProps.titleImage.file.url : null} />
    }
    else if(updateTableProps.platformManufacturer) {
        logo = <img className={"img-responsive"} width={updateTableProps.platformManufacturer.logo ? updateTableProps.platformManufacturer.logo.file.details.image.width : null} height={updateTableProps.platformManufacturer.logo ? updateTableProps.platformManufacturer.logo.file.details.image.height : null} src={updateTableProps.platformManufacturer.logo ? updateTableProps.platformManufacturer.logo.file.url : null} />
    }

    if(updateTableProps.updateTable){
      table = <div
    dangerouslySetInnerHTML={{
      __html: updateTableProps.updateTable.childMarkdownRemark.html,
    }}
  />
    }


return (
<div className={styles.updateTableSection}>
<h3>{logo}<span className={"sr-only"}>{title}</span></h3>
  {table}

</div>
    
)
  }
}

export default ProductUpdateTable
